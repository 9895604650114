<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header card-header-stretch">
        <h3 class="card-title fw-bolder text-gray-800 fs-2">Konta klienta</h3>
        <div class="card-toolbar m-0">
  				<ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
  					<li @click="type='all'" class="nav-item" role="presentation">
  						<a :class="type=='all' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Wszyscy klienci</a>
  					</li>
  					<li @click="type='external'" class="nav-item" role="presentation">
  						<a :class="type=='external' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Klienci zewnętrzni</a>
  					</li>
  					<li @click="type='internal'" class="nav-item" role="presentation">
  						<a :class="type=='internal' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Organy AAB</a>
  					</li>
  				</ul>
			   </div>
      </div>
      <div class="card-body py-0">
        <div class="table-responsive">
          <table class="table align-middle table-row-bordered table-row-dashed gy-5" id="kt_table_widget_1">
            <tbody>
              <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                <th class="min-w-125px px-0">Nazwa</th>
                <th class="min-w-100px text-center">Status</th>
                <th class="min-w-125px">Uprawnienia</th>
              </tr>
              <tr v-for="organisation in organisations">
                <td class="p-0 align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <router-link :to="{ name: 'AdminOrganisationDashboard', params: { id: organisation.id }}" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ organisation.name }}
                      </router-link>
                      <span class="text-gray-400 fw-bold d-block">
                        {{ organisation.id }}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="align-top pt-5 pb-5 text-center">
                  <span v-if="organisation.internal_client" class="badge badge-info badge-copy">Organ AAB</span>
                  <span v-else class="badge badge-primary badge-copy">Klient zewnętrzny</span>
                </td>
                <td class="align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    {{ organisation.role_name }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminOrganisations',
  inject: ['$profile'],
  data() {
    return {
      organisations: [],
      type: 'all'
    }
  },
  mounted () {
    this.reload();
    this.timer = setInterval(this.reload, 60000);
  },
  methods: {
    reload: function() {
      if (!this.profile.is_admin) return;
      var self = this;
      var url = 'organisations';
      if (this.type != 'all')
      {
        url = url + '?type=' + this.type;
      }
      this.$root.$refs.api.get(url).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.organisations = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  computed: {
    profile() {
      return this.$profile();
    }
  },
  watch: {
    type: function() {
      this.reload();
    }
  }
};
</script>

<style>
ul.scopes-list {
  list-style-type: none;
  padding: 0;
}
ul.scopes-list li {
  padding: 0;
}
</style>
